<template>
  <div class="reportInventoryPage">
    <mts-table ref="dataTable"
      v-model="pageItems" :pager="{ 'current-page': current, 'page-size':size, total }"
      :loading.sync="dataListLoading" :config="tableConfig"
      @pager:size-change="sizeChanged"
      @pager:current-change="pageChanged">
      <template #tableHeader>
        <el-form class="searchForm" inline>
          <div class="pageTypeBox">
            <el-radio-group v-model="pageType" size="small" @change="changePageType">
              <el-radio-button v-for="(item) in pageTypeItems" :key="item.key" :label="item.key">{{ item.label }}</el-radio-button>
            </el-radio-group>
          </div>
          <el-form-item>
            <el-autocomplete v-model="ownerName" size="small" ref="ownerNameInput"
              placeholder="货主编号或名称" class="fullWidth" prefix-icon="el-icon-search"
              :fetch-suggestions="ownerSuggestion" @select="(v) => ownerChanged(v)"
              @change="(v) => ownerChanged(v)" @blur="resetSearchText('owner')">
              <i v-if="ownerName !== ''" slot="suffix" class="el-input__icon show-on-focus el-icon-error" @click="removeSearchText('owner')"></i>
            </el-autocomplete>
          </el-form-item>
          <el-form-item v-if="pageType === 1">
            <tree-select-box v-model="searchForm.productCategoryId" :options="productCategoryList" placeholder="商品分类"></tree-select-box>
          </el-form-item>
          <template v-if="pageType !== 4">
            <el-form-item>
              <tree-select-box v-model="searchForm.teaCategoryId" :options="teaCategoryList" placeholder="茶叶品类"></tree-select-box>
            </el-form-item>
            <el-form-item>
              <el-select class="input item" v-model="searchForm.levelStandardId" @change="levelStandardChanged" size="small" placeholder="等级标准" clearable>
                <el-option v-for="(item, idx) in levelStandardList" :key="idx" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchForm.levelId" :disabled="!searchForm.levelStandardId" placeholder="等级" clearable size="small">
                <el-option v-for="(item, idx) in levelList" :key="idx" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <el-form-item>
            <el-input v-model="searchForm.keyWord" placeholder="请输入搜索关键词" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchDataList()" size="small" type="primary">搜索</el-button>
            <el-button size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </mts-table>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import mtsTable from '@/components/mts/table'
import treeSelectBox from '@/components/treeSelectBox'
// import _ from 'lodash'

export default {
  name: 'reportInventoryPage',
  components: { mtsTable, treeSelectBox },
  data () {
    return {
      pageType: 0,
      pageTypeItems: [
        { key: 0, label: '全部' },
        { key: 1, label: '产成品' },
        { key: 2, label: '散茶' },
        { key: 3, label: '准成品' },
        { key: 4, label: '包装物料' }
      ],
      ownerName: '',
      ownerNameOld: '',
      levelStandardList: [],
      levelList: [],
      teaCategoryList: [],
      productCategoryList: [],
      searchForm: {
        productCategoryId: null,
        teaCategoryId: null,
        levelStandardId: null,
        levelId: null,
        ownerId: null,
        keyWord: ''
      },
      dataListLoading: false,
      extendColumns: []
    }
  },
  activated () {
    this.getLevelStandardList()
    this.getProductCategoryTree()
    this.getTeaCategoryTree()
    this.getTenantList().then((data) => {
      this.setColumns(data)
      this.$nextTick(() => {
        this.$refs.dataTable.doLayout()
        this.getDataList()
      })
    })
  },
  computed: {
    ...mapState('reportInventory', ['current', 'size', 'total', 'pageItems']),
    tableConfig () {
      let commonItems1 = [{
        key: 'code',
        label: '规格编码',
        minWidth: 180,
        fixed: 'left'
      }, {
        key: 'name',
        label: '商品名称',
        minWidth: 180,
        fixed: 'left',
        showOverflowTooltip: true
      }, {
        key: 'specification',
        label: '商品规格',
        minWidth: 180,
        fixed: 'left',
        showOverflowTooltip: true
      }, {
        key: 'ownerName',
        label: '货主',
        minWidth: 120,
        showOverflowTooltip: true
      }, {
        key: 'supplierList',
        label: '供应商',
        minWidth: 200,
        showOverflowTooltip: true,
        formatter (r, c, value) {
          if (Array.isArray(value)) {
            return value.map((v) => v.name).join('、')
          } else {
            return ''
          }
        }
      }, {
        key: 'levelName',
        label: '等级',
        showOverflowTooltip: true,
        formatter (row) {
          let ret = row.levelName
          if (row.levelStandardName) ret += `（${row.levelStandardName}）`
          return ret
        }
      }, {
        key: 'spuTypeName',
        label: '商品类型'
      }]
      let commonItems2 = [{
        key: 'unitName',
        label: '基本单位',
        showOverflowTooltip: true
      }, {
        key: 'quantity',
        label: '总库存量'
      }]
      let spuTypeItem = []
      if (this.pageType !== 4) {
        spuTypeItem.push({
          key: 'productCategoryName',
          label: '商品分类',
          showOverflowTooltip: true
        })
        spuTypeItem.push({
          key: 'teaCategoryName',
          label: '茶叶品类',
          showOverflowTooltip: true
        })
      }
      return {
        tableProps: {
          border: true
        },
        items: [
          ...commonItems1,
          ...spuTypeItem,
          ...commonItems2,
          ...this.extendColumns
        ]
      }
    }
  },
  methods: {
    ...mapActions('reportInventory', [ 'page', 'getOwnerList', 'getTenantList' ]),
    // 动态设置列信息
    setColumns (json) {
      let { code, data } = json
      if (code !== 0 || !Array.isArray(data)) return
      let ret = data.map((item) => {
        return {
          key: item.id,
          label: `${item.name}库存`,
          formatter: (row, col) => {
            return this.getTenantVolumn(row, col.property) || 0
          }
        }
      })
      this.$set(this, 'extendColumns', ret)
    },
    // 获取对应列的数据
    getTenantVolumn (row, id) {
      // console.log(row)
      if (Array.isArray(row.tenantInventoryList)) {
        let ret = row.tenantInventoryList.find((v) => {
          return v.tenantId === id
        })
        if (ret) return ret.quantity || 0
      }
      return 0
    },
    // 获取茶叶品类
    getTeaCategoryTree () {
      this.$http.get('/teaCategory/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(data)
        if (code === 0) {
          this.teaCategoryList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 获取商品分类
    getProductCategoryTree () {
      this.$http.get('/inventory/getProductCategoryAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(data)
        if (code === 0) {
          this.productCategoryList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 获取等级列表
    getLevelStandardList () {
      this.$http.get('/levelStandard/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log('level list', data)
        if (code === 0) {
          this.levelStandardList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    levelStandardChanged (value) {
      this.searchForm.levelId = null
      if (value) this.getLevelList(value)
    },
    getLevelList (id) {
      this.$http.get(`/level/getAllList/${id}`).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log('level list', data)
        if (code === 0) {
          this.levelList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 货主下拉框
    ownerSuggestion (keyWord, callback) {
      this.getOwnerList({
          keyWord,
          page: 1,
          limit: 20
      }).then((res) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let items = data.records.map((v) => {
            return {
              id: v.id,
              value: v.name
            }
          })
          callback(items)
        } else {
          this.$message.error(msg)
        }
      })
    },
    ownerChanged (item) {
      let oldId = this.searchForm.ownerId
      if (typeof item === 'string') {
        if (oldId && item !== this.ownerNameOld) {
          this.$set(this, 'ownerName', this.ownerNameOld)
        }
      } else {
        this.$set(this.searchForm, 'ownerId', item.id)
        this.ownerNameOld = item.value
      }
    },
    removeSearchText (name) {
      this.$set(this, name + 'Name', '')
      // this.$set(this, name + 'NameOld', '')
      // this.$set(this.dataForm, name + 'Id', null)
      this.$nextTick(() => {
        this.$refs[name + 'NameInput'].focus()
      })
    },
    resetSearchText (name) {
      if (this.searchForm[name + 'Id'] && this[name + 'NameOld'] !== '') {
        this[name + 'Name'] = this[name + 'NameOld']
      } else if (!this.searchForm[name + 'Id']) {
        this[name + 'Name'] = this[name + 'NameOld'] = ''
      }
    },
    changePageType () {
      this.resetSearch()
    },
    getSupplierNames (items) {
      let ret = items.map((item) => item.name)
      return ret.join('、')
    },
    sizeChanged (size) {
      this.getDataList(this.current, size)
    },
    pageChanged (page) {
      this.getDataList(page)
    },
    searchDataList () {
      this.getDataList(1)
    },
    resetSearch () {
      let form = this.$options.data().searchForm
      this.$set(this, 'searchForm', form)
      this.ownerName = this.ownerNameOld = ''
      this.$nextTick(() => {
        this.getDataList(1)
      })
    },
    getDataList (p, s) {
      if (!p) p = this.current
      if (!s) s = this.size
      this.dataListLoading = true
      let params = {
        page: p,
        limit: s,
        spuTypeId: this.pageType,
        ...this.searchForm
      }
      if (this.pageType === 0) {
        delete params.spuTypeId
      }
      return this.page(params).then(() => {
        this.dataListLoading = false
        this.$refs.dataTable.doLayout()
      }).catch((e) => {
        this.dataListLoading = false
        this.$message({
          message: e.message,
          type: 'warning',
          duration: 5000
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.reportInventoryPage {
  .searchForm {
    margin-bottom: -18px;
  }
  .pageTypeBox {
    display: inline-block;
    margin-right: 10px;
  }
}
</style>
